import ImageGroq from './ImageGroq';

const DonationGroq = `{
  _id,
  _type,
  headline,
  headerImage${ImageGroq},
  bodyText,
  footerText,
  donationEmbedCode,
  "seo": {
    "title": seoTitle,
    "description": seoDescription,
    "image": seoImage${ImageGroq}
  }
}`;

export default DonationGroq;
