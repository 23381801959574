import { RouteObject } from 'types';

type ExternalLink = {
  path: string;
};

/**
 * This enum helps with typechecking in the LegacyRerouteView
 */
export enum LegacyPaths {
  Article = '/:category/:id/:slug',
  Tags = '/tag/:tag',
  Contributor = '/author/:authorSlug',
  Contributors = '/authors/:authorSlug',
}

export const RouteMap: { [id: string]: RouteObject } = {
  HOME: {
    path: '/',
    exact: true,
  },
  RECIPE_ARTICLE: {
    path: '/recipes/:slug',
    base: '/recipes',
    exact: true,
  },
  RECIPES: {
    path: '/recipes',
    base: '/recipes',
    exact: true,
  },
  RECIPES_BY_HOLIDAY: {
    path: '/recipes/by-holiday/:slug',
    base: '/recipes/by-holiday',
    exact: true,
  },
  RECIPES_BY_SEASON: {
    path: '/recipes/by-season/:slug',
    base: '/recipes/by-season',
    exact: true,
  },
  ARCHIVE: {
    path: '/sections/:slug/archive',
    exact: true,
    base: '/sections/',
  },
  ARTICLE: {
    path: '/sections/:section/articles/:slug',
    base: '/sections',
    exact: true,
  },
  ARTICLE_CATCH: {
    path: '/sections/:slug/article/:slug',
    exact: false,
  },
  ARTICLE_SECTION_CATCH: {
    path: '/section/:slug/articles/:slug',
    exact: false,
  },
  ARTICLE_SECTION_CATCH_BOTH: {
    path: '/section/:slug/article/:slug',
    exact: false,
  },
  CONTRIBUTOR: {
    path: '/contributors/:authorSlug',
    exact: true,
    base: '/contributors/',
  },
  CONTRIBUTOR_LEGACY: {
    path: LegacyPaths.Contributor,
    exact: true,
    base: '/author/',
  },
  CONTRIBUTORS_LEGACY: {
    path: LegacyPaths.Contributors,
    exact: true,
    base: '/author/',
  },
  CONTRIBUTORS: {
    path: '/contributors',
    exact: true,
  },
  TAGS_404: {
    path: '/tags',
    exact: true,
  },
  TAGS: {
    path: '/tags/:tag',
    exact: true,
    base: '/tags/',
  },
  TAGS_LEGACY: {
    path: LegacyPaths.Tags,
    exact: true,
    base: '/tag/',
  },
  HOLIDAY: {
    path: '/sections/holidays/:slug',
    exact: true,
    base: '/sections/holidays/',
  },
  SECTION: {
    path: '/sections/:slug',
    exact: true,
    base: '/sections/',
  },
  SECTION_CATCH: {
    path: '/section/:slug',
    exact: false,
  },
  SECTION_ALL: {
    path: '/sections/:slug/articles',
    exact: true,
    base: '/sections/',
  },
  SECTION_ALL_CATCH: {
    path: '/section/:slug/articles',
    exact: true,
    base: '/sections/',
  },
  ENCYCLOPEDIA: {
    path: '/encyclopedia',
    exact: true,
  },
  MOCKS: {
    path: '/mocks/:id',
    exact: true,
  },
  ABOUT_EDITOR: {
    path: '/about/:slug',
    exact: true,
    base: '/about/',
  },
  PRINT_RECIPE: {
    path: '/articles/:articleSlug/print/:recipeSlug',
    exact: true,
  },
  PODCASTS: {
    path: '/podcasts',
    exact: true,
  },
  PODCAST: {
    path: '/podcasts/:podcastSlug',
    exact: true,
    base: '/podcasts/',
  },
  PODCAST_EPISODE: {
    path: '/podcasts/:podcastSlug/:podcastEpisodeSlug',
    exact: true,
    base: '/podcasts/',
  },
  PRIVACY_POLICY: {
    path: '/privacy-policy',
    exact: true,
  },
  TERMS_OF_SERVICE: {
    path: '/terms-of-service',
    exact: true,
  },
  NEWSLETTER: {
    path: '/newsletter',
    exact: true,
  },
  DONATE: {
    path: '/donate',
    exact: true,
  },
  SEARCH: {
    path: '/search',
    exact: true,
    base: '/search',
  },
  LEGACY_ARTICLE: {
    path: LegacyPaths.Article,
    exact: false,
  },
  FEATURE_ARTICLE: {
    path: '/feature/:slug',
    exact: true,
    base: '/feature/',
  },
};

export const DevRouteMap: { [id: string]: RouteObject } = {
  LEGACY_INDEX: {
    path: '/legacy-index',
    exact: true,
  },
  ARTICLE_PREVIEW: {
    path: '/articles/:slug',
    exact: false,
  },
};

export const MockRoute = {
  LONG_FORM_WITH_ADVERTISEMENTS: '/mocks/long-form-with-advertisements',
  LONG_FORM_WITH_QUOTES: '/mocks/long-form-with-quotes',
};

// TO-DO: This must come from the CMS and can't static.
export const ExternalLinksRouteMap: { [id: string]: ExternalLink } = {
  FACEBOOK: {
    path: 'https://www.facebook.com/tabletmag',
  },
  INSTAGRAM: {
    path: 'https://www.instagram.com/tabletmag',
  },
  TWITTER: {
    path: 'https://x.com/tabletmag',
  },
};
