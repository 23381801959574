import React, { useCallback, useEffect, useState } from 'react';
import { Router } from 'next/router';
import dynamic from 'next/dynamic';

import cx from 'classnames';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import withBreakpoints, {
  InjectedProps as WithBreakpointsProps,
} from '../lib/withBreakpoints';
import getSectionNavPanelWidth from '../utils/getSectionNavPanelWidth';
import * as FeatureFlags from '../utils/featureFlags';
import { Button } from '../components/base';
import Footer from '../components/Footer';
import { definitely } from '../utils/definitely';
import { HexColors } from '../constants/Colors';
import { Theme } from '../types';
import { RouteMap } from '../constants/RouteMap';
import Language from '../constants/Language';
import { withUI, UIContextValue as UIProps } from '../providers/UIProvider';
import {
  withSiteSettings,
  SiteSettingsContextValue as SiteSettingsProps,
} from '../providers/SiteSettingsProvider';
import { useIsFiction } from '../hooks/useFiction';
import { SectionMenuLink } from '../sharedTypes';

const RecentArticlesBySection = dynamic(
  () => import('../components/RecentArticlesBySection')
);
const DonationPopup = dynamic(() => import('../components/DonationPopup'));
const EmailSignupPopup = dynamic(
  () => import('../components/NewsletterForm/EmailSignupPopup')
);
const NewsletterForm = dynamic(() => import('../components/NewsletterForm'));
const SectionNav = dynamic(() => import('../components/SectionNav'));
const SideNav = dynamic(() => import('../components/SideNav'));

const linkSefaria = () => {
  // @ts-ignore
  if (!window.sefaria) return;

  // NEXT_TODO: pass in props from the Next.js context to determine
  // if the page is NotFound or an Error page
  const isNotFound = Boolean(document.querySelector('.PageNotFound'));
  const isErrorPage = Boolean(document.querySelector('.ErrorPage'));
  if (isNotFound || isErrorPage || process.env.NODE_ENV === 'development') {
    return;
  }
  // @ts-ignore
  window.sefaria.link({ selector: '.sefaria-parse' });
};

interface State {
  sectionNavSize: number;
  addSectionNavActiveClassName: boolean;
}

type Props = WithBreakpointsProps & {
  router: Router;
  theme: Theme;
  children?: React.ReactNode;
} & SiteSettingsProps &
  UIProps;

const setBodyColor = (theme: Theme) => {
  if (theme === Theme.Default) {
    document.body.style.backgroundColor = HexColors['off-white'];
  } else if (theme === Theme.Beige) {
    document.body.style.backgroundColor = HexColors['beige'];
  } else if (theme === Theme.CollectionWhite) {
    document.body.style.backgroundColor =
      HexColors['collection-background-grey'];
  } else if (theme === Theme.Black) {
    document.body.style.backgroundColor = HexColors['black'];
  } else if (theme === Theme.Sand) {
    document.body.style.backgroundColor = HexColors['sand'];
  }
};

/* TO-DO: Add logic to hide Recent Articles on new Recipe pages */
// NEXT_TODO:
// - QA this to make sure the next.js routes work with the legacy
//   Routemap
// - handle this logic in <RecenArticlesBySection />
const shouldRenderRecentArticlesBySection = (pathname: string) =>
  FeatureFlags.isEnabled(
    FeatureFlags.Flags.FOOTER_RECENT_ARTICLES_BY_SECTION
  ) &&
  !pathname.includes('/404') &&
  !pathname.includes('/500') &&
  !pathname.includes('/print/') &&
  !pathname.includes('/collections') &&
  !pathname.includes('/columnists') &&
  !pathname.includes('/columns') &&
  !pathname.includes(RouteMap.RECIPES.base as string) &&
  !pathname.includes(RouteMap.PODCASTS.path as string) &&
  !pathname.includes(RouteMap.ENCYCLOPEDIA.path as string) &&
  !pathname.includes(RouteMap.CONTRIBUTORS.path as string) &&
  !pathname.includes(RouteMap.TAGS.base as string) &&
  !pathname.includes(RouteMap.SEARCH.base as string) &&
  !pathname.includes(RouteMap.HOLIDAY.base as string) &&
  !pathname.includes(RouteMap.FEATURE_ARTICLE.base as string) &&
  !pathname.includes(RouteMap.DONATE.path as string);

const App: React.FC<Props> = (props) => {
  const {
    children,
    globalSettings,
    recentArticlesBySection,
    router,
    sideNavIsOpen,
    sectionNavIsOpen,
    theme,
    orderedMenuSections,
    totalSections,
    currentBreakpoint,
    openSideNav,
    closeSectionNav,
    closeSideNav,
    preview,
  } = props;

  const isFiction = useIsFiction();

  const [state, setState] = useState<State>({
    sectionNavSize: 0,
    addSectionNavActiveClassName: false,
  });

  const setSectionNavSize = useCallback(() => {
    const sectionNavPanelSize = getSectionNavPanelWidth();

    const sizes = {
      mobile: sectionNavPanelSize + sectionNavPanelSize * (totalSections - 1),
      tablet:
        sectionNavPanelSize * (totalSections - 3.25) -
        (window.innerWidth - window.innerHeight),
      largeTablet:
        sectionNavPanelSize * (totalSections - 2.5) -
        (window.innerWidth - window.innerHeight),
      desktop:
        sectionNavPanelSize * (totalSections - 2.45) -
        (window.innerWidth - window.innerHeight),
      desktopXL:
        sectionNavPanelSize * (totalSections - 2.275) -
        (window.innerWidth - window.innerHeight),
    };

    const breakpoint = {
      isMedium: ['MEDIUM'].includes(currentBreakpoint),
      isLargeMedium: ['LARGE_MEDIUM'].includes(currentBreakpoint),
      isLargeUp: ['LARGE'].includes(currentBreakpoint),
      isExtraLargeUp: ['EXTRA_LARGE', 'EXTRA_EXTRA_LARGE'].includes(
        currentBreakpoint
      ),
    };

    let newSectionNavSize: number;

    if (breakpoint.isExtraLargeUp) {
      newSectionNavSize = sizes.desktopXL;
    } else if (breakpoint.isLargeUp) {
      newSectionNavSize = sizes.desktop;
    } else if (breakpoint.isLargeMedium) {
      newSectionNavSize = sizes.largeTablet;
    } else if (breakpoint.isMedium) {
      newSectionNavSize = sizes.tablet;
    } else {
      newSectionNavSize = sizes.mobile;
    }

    return setState((prevState) => ({
      ...prevState,
      sectionNavSize: newSectionNavSize,
    }));
  }, [totalSections, currentBreakpoint]);

  useEffect(() => {
    setSectionNavSize();
    window.addEventListener('resize', setSectionNavSize);

    return () => {
      window.removeEventListener('resize', setSectionNavSize);
    };
  }, [setSectionNavSize]);

  useEffect(() => {
    linkSefaria();
  }, [router.pathname]);

  useEffect(() => {
    setBodyColor(theme);
  }, [theme]);

  useEffect(() => {
    const { currentBreakpoint } = props;
    const breakpointIsMediumUp = [
      'MEDIUM',
      'LARGE',
      'LARGE_MEDIUM',
      'EXTRA_LARGE',
      'EXTRA_EXTRA_LARGE',
    ].includes(currentBreakpoint);

    if (props.sectionNavIsOpen) {
      if (!breakpointIsMediumUp) {
        document.body.style.maxWidth = 'none';
        document.body.style.overflowX = 'scroll';
      }

      setTimeout(
        () =>
          setState((prevState) => ({
            ...prevState,
            addSectionNavActiveClassName: true,
          })),
        800
      );
    } else {
      setState((prevState) => ({
        ...prevState,
        addSectionNavActiveClassName: false,
      }));
      document.body.style.maxWidth = '100vw';
      document.body.style.overflowX = 'hidden';
    }
  }, [props, props.sectionNavIsOpen]);

  const getAppStyle = () => {
    const { sectionNavSize } = state;
    const sectionPageLength = totalSections - 1;

    const breakpointIsMediumUp = [
      'MEDIUM',
      'LARGE',
      'LARGE_MEDIUM',
      'EXTRA_LARGE',
      'EXTRA_EXTRA_LARGE',
    ].includes(currentBreakpoint);

    if (sectionNavIsOpen) {
      return breakpointIsMediumUp
        ? {
            minHeight: `${sectionNavSize - sectionPageLength}px`,
            maxHeight: `${sectionNavSize - sectionPageLength}px`,
          }
        : {
            minWidth: `${sectionNavSize - sectionPageLength}px`,
            maxWidth: `${sectionNavSize - sectionPageLength}px`,
          };
    }

    return {};
  };

  const donationPopup = globalSettings?.donationPopup || null;
  const emailSignUpPopup = globalSettings?.emailSignUpPopup || null;
  const membershipLink = globalSettings?.membershipLink;
  const tabletAppLink = globalSettings?.tabletAppLink;
  return (
    <main
      className={cx('App relative', {
        'bg-color-off-white':
          theme === Theme.Default || router.pathname.includes('/404'),
        'bg-color-beige': theme === Theme.Beige,
        'bg-color-black': theme === Theme.Black,
        'bg-color-collections': router.pathname.includes('/collections'),
      })}
      style={getAppStyle()}
    >
      <nav
        className={cx(
          'SideNav relative fixed bg-color-black t0 vh100 z-sidenav transition z-nav overflow-y-scroll',
          {
            'SideNav--active': sideNavIsOpen,
          }
        )}
      >
        {sideNavIsOpen && (
          <SideNav
            sectionPages={orderedMenuSections as SectionMenuLink[]}
            sideNavIsOpen={sideNavIsOpen}
            closeSideNav={() => {
              closeSideNav();
              closeSectionNav();
            }}
            router={router}
            membershipLink={membershipLink}
            tabletAppLink={tabletAppLink}
          />
        )}
      </nav>

      {sectionNavIsOpen && (
        <SectionNav
          sectionNavIsOpen={sectionNavIsOpen}
          closeSectionNav={() => {
            closeSectionNav();
            closeSideNav();
          }}
          showSideNav={openSideNav}
          sectionNavSize={state.sectionNavSize}
        />
      )}

      <div
        className={cx('App__content-container relative transition', {
          'App__content-container--side-nav-is-active': sideNavIsOpen,
          'App__content-container--section-nav-is-active': sectionNavIsOpen,
          'fiction-page': isFiction,
          'App__content-container--collections':
            router.pathname.includes('/collections'),
          // state.addSectionNavActiveClassName, //sectionNavIsOpen
        })}
      >
        <TransitionGroup>
          <CSSTransition
            timeout={{ exit: 300 }}
            key={router.route}
            classNames="fade"
          >
            <div
              className={cx('App__main content-height', {
                'App__main--side-nav-active': sideNavIsOpen,
              })}
            >
              {children}
            </div>
          </CSSTransition>
        </TransitionGroup>

        {shouldRenderRecentArticlesBySection(props.router.pathname) && (
          <RecentArticlesBySection sections={recentArticlesBySection} />
        )}

        {!router.pathname.includes('/print/') &&
          !router.pathname.includes('/feature/') && (
            <Footer
              removeBorderTop={
                // NEXT_TODO:
                //   - refactor this to work with Next.js pages
                //   - handle this logic within <Footer />
                //   (right now this will not work correctly because
                //   router.pathname will not match RouteMap paths)
                router.pathname === RouteMap.HOME.path ||
                router.pathname === RouteMap.SECTION.path ||
                router.pathname === RouteMap.PODCAST.path ||
                router.pathname === RouteMap.TAGS.path ||
                router.pathname === RouteMap.SEARCH.path ||
                router.pathname === RouteMap.CONTRIBUTOR.path
              }
              removeMaxWidth={
                router.pathname === RouteMap.HOME.path ||
                router.pathname === RouteMap.SECTION.path
              }
              isCollectionsPath={
                router.pathname.includes('collections') ||
                router.pathname.includes('columns')
              }
            />
          )}
      </div>

      {sideNavIsOpen && !sectionNavIsOpen && (
        <Button
          ariaLabel={Language.t('Global.closeSideNav')}
          onClick={closeSideNav}
          className="App__close-side-nav-button bg-color-transparent vw100 vh100 fixed t0 z-overlay trigger-SideNavMain-NavSideNavClose"
        />
      )}

      {donationPopup?.isActive ? (
        <DonationPopup
          title={donationPopup.title}
          description={donationPopup.description}
          popupIsActive={donationPopup.isActive}
          link={donationPopup.link}
          amounts={donationPopup.amounts}
        />
      ) : (
        emailSignUpPopup?.isActive && (
          <EmailSignupPopup>
            <NewsletterForm asPopup {...emailSignUpPopup} />
          </EmailSignupPopup>
        )
      )}
    </main>
  );
};

export default withBreakpoints(withSiteSettings(withUI(App)));
