import get from 'lodash/get';
import { Donation } from 'sharedTypes';

const ALLOWED_DOMAINS = ['secure.qgiv.com', 'forms.qgiv.com'];

const sanitizeEmbedCode = (html: string): string => {
  return html.replace(
    /<(iframe|script)[^>]*(src=["']([^"']+)["'])[^>]*>/gi,
    (match, tag, srcAttr, src) => {
      try {
        const url = new URL(src);
        if (ALLOWED_DOMAINS.includes(url.hostname)) {
          return match;
        }
      } catch (e) {
        // Invalid URL, remove the tag
      }
      return '';
    }
  );
};

const sanitizeDonationPageResponse = (data: unknown): Donation => {
  const rawEmbedCode = get(data, 'donationEmbedCode', '');

  const sanitizedEmbedCode = sanitizeEmbedCode(rawEmbedCode);

  return {
    _type: get(data, '_type', 'donationPage'),
    headline: get(data, 'headline', ''),
    bodyText: get(data, 'bodyText', []),
    footerText: get(data, 'footerText', []),
    donationEmbedCode: sanitizedEmbedCode,
    headerImage: {
      id: get(data, 'headerImage.id', ''),
      src: get(data, 'headerImage.src', ''),
      alt: get(data, 'headerImage.alt', ''),
      crop: get(data, 'headerImage.crop', {}),
    },
    seo: {
      title: get(data, 'seo.title', ''),
      description: get(data, 'seo.description', ''),
      image: {
        id: get(data, 'seo.image.id', ''),
        src: get(data, 'seo.image.src', ''),
      },
    },
  };
};

export default sanitizeDonationPageResponse;
